<template>
    <div class="shop-block" @click="quickView(item)" >
        <router-link to="">
            <img :src="itemImages" class="img-fluid" alt="shop assets">
            <h3>{{itemTitle}}</h3>
            <template v-if="isOffer">
                <span class="shopPrice"><del>{{itemPriceDisplay}}</del></span>
                <span class="shopPrice">{{ itemOfferPriceDisplay }}</span>
            </template>
            <template v-else>
                <span class="shopPrice">{{ itemPriceDisplay }} </span>
            </template>

            <div class="quickBuy" v-b-tooltip.hover title="Quick View">
                <span class="icon-view"></span>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "AssetItem",
    props:{
        item:{
            required: true,
        }
    },
    data(){
        return {
            itemTitle:this.item.title,
            itemImages: this.item.image,
            itemPriceDisplay:this.item.display_price,

            isOffer:this.item.is_offer,
            itemOffer:this.item.offer_percentage,
            itemOfferPriceDisplay:this.item.display_offer_price
        }
    },
    methods:{
        quickView(item){
            let app = this;
            app.$root.$emit("quickview-status", true);
            app.$bvModal.show('AssetQuickViewModal');
            app.$root.$emit("item-data", item);
        },
    },
    watch: {
        item(value) {
            let app = this;
            app.itemTitle  =  value.title,
            app.itemImages =  value.image;
            app.itemPriceDisplay= value.display_price,

            app.isOffer    = value.is_offer,
            app.itemOffer  = value.offer_percentage,
            app.itemOfferPriceDisplay = value.display_offer_price
        },
    },

}
</script>