<template>
    <div class="">
        <h2> Data not found!</h2>
    </div>
</template>
<script>
export default {
    name: 'NotFound',

}
</script>