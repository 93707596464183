<template>
    <div>
        <section class="innerBanner">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadcrumb">
                            <ul>
                                <li>
                                    <router-link to="">
                                        <span class="icon-home"></span>
                                    </router-link>
                                </li>
                                <li>
                                    <router-link to="">Assets</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="pageTitle">
                            <h1>Assets</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="pageWrap">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-lg-3">
                        <AssetsFilter />
                    </div>
                    <div class="col-xl-9 col-lg-9">
                        <AssetsListing />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AssetsFilter from './partials/AssetsFilter.vue';
import AssetsListing from './partials/AssetsListing.vue'
export default {
    name: 'AssetsList',
    components: { AssetsFilter, AssetsListing},
    mounted() {
        document.title = 'Assets';
    },
}
</script>