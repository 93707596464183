<template>
    <div class="courseListing-wrap">
        <div class="listing-info row align-items-center">
            <div class="col-xl-9 col-lg-9 col-md-6">
                <p> Showing {{ from }} – {{ to }} of {{ total }} results </p>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 my-3">
                <select v-model="filterData.sort" @change="onChange($event)" class="univ-input" >
                    <option value="latest">Newest</option>
                    <option value="priceasc">Price low to high</option>
                    <option value="pricedesc">Price high to low</option>
                </select>
            </div>
        </div>
        <div class="row" v-if="assets.data.length > 0 || pageData">
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6" v-for="(item,i) in assets.data" :key="i" >
                <AssetItem :item="item" />
            </div>
        </div>
        <div class="row" v-else>
            <NotFound />
        </div>
        <div class="row justify-content-center mt-3 mb-5" v-if="to !== total">
            <div class="col-md-3">
                <button class="univ-btn w-100" @click="loadMore" >Load More</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import AssetItem from '@/components/AssetItem'
import NotFound from '@/components/NotFound'
import Helper from "@/services/helper"

export default {
    name: 'AssetsListing',
    components :{ AssetItem, NotFound },
    data() {
        return {
            pageData: true,
            title:"",
            description:"",
            assets: { 
                data: [],
                links: {}, 
                meta: {}
            },
            from:"0",
            to:"0",
            total:"0",
            last_page:"",
            filterData:{
                page:1,
                limit: 6,
                keywords:"",
                level:"",
                category:"",
                sort:"latest",
            },
        };        
    },
    mounted() {
        let app = this;
        app.searchItems();
        app.$root.$on("filter-items", ($event) => {
            app.filterData = $event;
            app.searchItems();
        });
    },
    methods: {
        searchItems(){
            let app = this;
            Helper.showSpinner();
            axios.post(api.getUrl('/search/assets'), app.filterData).then(function (response) {
                if(response.data.success){
                    app.pageData = false;
                    let responseData     = response.data.data;
                    app.title       = responseData.title;
                    app.description = responseData.description;
                    app.assets.data = responseData.data;

                    app.from    =   responseData.meta.from;
                    app.to      =   responseData.meta.to;
                    app.total   =   responseData.meta.total;
                    app.last_page = responseData.meta.last_page;
                    app.filterData.limit = responseData.meta.per_page;
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
        onChange(event){
            let app = this;
            app.filterData.sort = event.target.value;
            app.searchItems();
        },
        loadMore(){
            let app = this;
            if(app.to <= app.total) {
                app.filterData.limit = app.filterData.limit * 2;
            }
            app.searchItems();
        },
    }
}
</script>